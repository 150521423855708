.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding: 30px;
    background-color: rgb(228, 228, 228);
}

.contact-card {
    width: 400px;
    height: 230px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    direction: rtl;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 0px 30px rgb(156, 156, 156);    align-items: center;
    transition: all 1s ease-in-out;
    border: 5px solid var(--darkwhite);
    margin: 10px;
  }

.contact-card h1{

    margin: 10px;
}

.contact-card a {
    margin: 5px;
    font-weight: 500;
}

.contact-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
    position: relative;
    left: 150px;
}

.contact-card span {
    margin: 10px;
    position: absolute;
    margin-top: 135px;
}