@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
:root{
  --primary: #D4AF37;
  --darkprimary: #94811e;
  --titleprimary: #7c5e00;
  --white: #f8f8f8;
  --darkwhite: #f1f1f1;
  --black: #22262a;
  --darkblack: #191c1f;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
}

body {
   background-color: #f1f1f1;
   background-color: var(--darkwhite);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.primary-color {
  color: #D4AF37;
  color: var(--primary);
}

.black-color {
  color: #22262a;
  color: var(--black);
}

.white-color {
  color: #f8f8f8;
  color: var(--white);
}
.navbar {
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 1rem;
    background-color: var(--white);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
.navbar-logo {
    font-weight: bold;
}
.navbar-hamburger {
    display: none;
}  
.navbar-menu {
    display: flex;
}
.logo-letter{
    color: var(--primary);
    transition: all .2s ease-in-out;;
}

.logo-letter:hover {
    color: var(--darkprimary);
}

@media screen and (max-width: 940px) {
    .navbar-hamburger {
        display: flex;
    }   
    .navbar-menu {
        display: none;
    }
}
.primary-container{
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 150px;
    background-image: url('/images/calculator.jpg');
    background-size: cover;
    text-align: center;
    transition: all 1s ease-in-out;
    text-shadow: 0px 0px 20px rgb(89, 89, 89);
}

.primary-title {
    font-size: 50px;
    font-weight: bold;
    color: var(--white);
}

.primary-subtitle {
    direction: rtl;
    font-size: 20px;
    font-weight: 600;
    color: var(--white);
}

@media screen and (max-width: 650px) {
    .primary-container{
        height: 500px;

    }
}
.about-container {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 30px rgb(220, 220, 220);

}

.about-title {
    margin-top: 15px;
    font-size: 30px;
    font-weight: bold;
    color: var(--titleprimary);
    text-shadow: 0px 0px 20px rgb(111, 111, 111);
}
.about-card {
    margin: 15px;
    /* border: 2px solid #cacaca; */
    border-radius: 15px;
    width: 40%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--darkwhite); */

}

.about-card-text {
    
    margin-top: -15px;
    direction: rtl;
    color: var(--darkprimary);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.jobs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    padding: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: 5px solid var(--darkwhite);
  background-color: var(--white);
  border-radius: 20px;
  width: 300px;
  height: 500px;
  margin: 20px;
  direction: rtl;
  box-shadow: 0px 0px 30px rgb(193, 193, 193);    align-items: center;
  padding: 10px;
  transition: all 1s ease-in-out;
}

.card-container:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0px 0px 30px rgb(146, 146, 146);
}

.card-image {
    position: relative;
    top: -30px;
    border-radius: 20px;
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 30px rgb(171, 171, 171);
}

.card-title {
    padding: 10px;
}

@media screen and (max-width: 940px) {
    .jobs-container {
        flex-direction: column;
    }
    
}
.contact-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding: 30px;
    background-color: rgb(228, 228, 228);
}

.contact-card {
    width: 400px;
    height: 230px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    direction: rtl;
    background-color: white;
    border-radius: 30px;
    box-shadow: 0px 0px 30px rgb(156, 156, 156);    align-items: center;
    transition: all 1s ease-in-out;
    border: 5px solid var(--darkwhite);
    margin: 10px;
  }

.contact-card h1{

    margin: 10px;
}

.contact-card a {
    margin: 5px;
    font-weight: 500;
}

.contact-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px;
    position: relative;
    left: 150px;
}

.contact-card span {
    margin: 10px;
    position: absolute;
    margin-top: 135px;
}
.footer-container {
    background-color: #6c6c6c;
    padding: 20px;
    text-align: center;
    color: white;
}

.footer-container a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}
