.footer-container {
    background-color: #6c6c6c;
    padding: 20px;
    text-align: center;
    color: white;
}

.footer-container a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}