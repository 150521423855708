.jobs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    padding: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: 5px solid var(--darkwhite);
  background-color: var(--white);
  border-radius: 20px;
  width: 300px;
  height: 500px;
  margin: 20px;
  direction: rtl;
  box-shadow: 0px 0px 30px rgb(193, 193, 193);    align-items: center;
  padding: 10px;
  transition: all 1s ease-in-out;
}

.card-container:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 30px rgb(146, 146, 146);
}

.card-image {
    position: relative;
    top: -30px;
    border-radius: 20px;
    width: 200px;
    height: 200px;
    box-shadow: 0px 0px 30px rgb(171, 171, 171);
}

.card-title {
    padding: 10px;
}

@media screen and (max-width: 940px) {
    .jobs-container {
        flex-direction: column;
    }
    
}