.navbar {
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 1rem;
    background-color: var(--white);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
.navbar-logo {
    font-weight: bold;
}
.navbar-hamburger {
    display: none;
}  
.navbar-menu {
    display: flex;
}
.logo-letter{
    color: var(--primary);
    transition: all .2s ease-in-out;;
}

.logo-letter:hover {
    color: var(--darkprimary);
}

@media screen and (max-width: 940px) {
    .navbar-hamburger {
        display: flex;
    }   
    .navbar-menu {
        display: none;
    }
}