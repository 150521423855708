.primary-container{
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 150px;
    background-image: url('/images/calculator.jpg');
    background-size: cover;
    text-align: center;
    transition: all 1s ease-in-out;
    text-shadow: 0px 0px 20px rgb(89, 89, 89);
}

.primary-title {
    font-size: 50px;
    font-weight: bold;
    color: var(--white);
}

.primary-subtitle {
    direction: rtl;
    font-size: 20px;
    font-weight: 600;
    color: var(--white);
}

@media screen and (max-width: 650px) {
    .primary-container{
        height: 500px;

    }
}