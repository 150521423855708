@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root{
  --primary: #D4AF37;
  --darkprimary: #94811e;
  --titleprimary: #7c5e00;
  --white: #f8f8f8;
  --darkwhite: #f1f1f1;
  --black: #22262a;
  --darkblack: #191c1f;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans';
}

body {
   background-color: var(--darkwhite);
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.primary-color {
  color: var(--primary);
}

.black-color {
  color: var(--black);
}

.white-color {
  color: var(--white);
}