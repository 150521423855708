.about-container {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 30px rgb(220, 220, 220);

}

.about-title {
    margin-top: 15px;
    font-size: 30px;
    font-weight: bold;
    color: var(--titleprimary);
    text-shadow: 0px 0px 20px rgb(111, 111, 111);
}
.about-card {
    margin: 15px;
    /* border: 2px solid #cacaca; */
    border-radius: 15px;
    width: 40%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: var(--darkwhite); */

}

.about-card-text {
    
    margin-top: -15px;
    direction: rtl;
    color: var(--darkprimary);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}